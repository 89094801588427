import React, { useEffect } from 'react';
import { useTheme } from './contexts/ThemeContext';
import Header from './components/Header';
import Footer from './components/Footer';
import ChatBot from './components/ChatBot';
import MouseTrail from './components/MouseTrail';
import AppRoutes from './Routes';
import { logEvent } from "firebase/analytics";
import { analytics } from './firebaseConfig';
import styles from './styles/AppStyles';

const AppContent = () => {
  const { isDarkMode } = useTheme();

  useEffect(() => {
    logEvent(analytics, 'page_view', {
      page_title: document.title,
      page_location: window.location.href,
    });
  }, []);

  return (
    <>
      <div style={{
        ...styles.body,
        backgroundColor: isDarkMode ? '#000' : '#fff',
        color: isDarkMode ? '#fff' : '#000',
      }}>
        <Header />
        <main style={styles.content}>
          <AppRoutes />
        </main>
        <Footer />
      </div>
      <ChatBot />
      <MouseTrail />
    </>
  );
};

export default AppContent;
