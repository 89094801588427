import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :root {
    --bg-dark: #1a1a1a;
    --bg-light: #f0f0f0;
    --text-dark: #e0e0e0;
    // --text-light: #333333;
    --primary-dark: #00ff00;
    --primary-light: #008000;
    --secondary-dark: #1c1c1c;
    --secondary-light: #e0e0e0;
    --font-size-small: 18px;  // Increased from 18px to 20px
    --font-size-base: 20px;   // Increased from 20px to 22px
    --font-size-large: 22px;  // Increased from 22px to 24px
    --font-size-xl: 28px;     // Increased from 28px to 30px
    --font-size-xxl: 36px;    // Increased from 36px to 38px
  }

  body {
    background-color: ${props => props.isDarkMode ? 'var(--bg-dark)' : 'var(--bg-light)'};
    color: ${props => props.isDarkMode ? 'var(--text-dark)' : 'var(--text-light)'};
    font-family: ${props => props.lang === 'ar' ? "'Droid Arabic Kufi'" : "'Fira Code', 'Roboto Mono', 'Courier New', monospace"};
    font-size: var(--font-size-base);
    line-height: 1.6;
    margin: 0;
    padding: 0;
    min-height: 100vh;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${props => props.lang === 'ar' ? "'Droid Arabic Kufi'" : "'Fira Code', 'Roboto Mono', 'Courier New', monospace"};
    color: ${props => props.isDarkMode ? 'var(--text-dark)' : 'var(--text-light)'};
  }

  a {
    color: ${props => props.isDarkMode ? 'var(--primary-dark)' : 'var(--primary-light)'};
    text-decoration: none;
    transition: color 0.3s ease;
    &:hover {
      text-decoration: underline;
    }
  }

  button {
    background-color: ${props => props.isDarkMode ? 'var(--primary-dark)' : 'var(--primary-light)'};
    color: ${props => props.isDarkMode ? 'var(--bg-dark)' : 'var(--bg-light)'};
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-family: 'Fira Code', 'Roboto Mono', monospace;
    font-size: var(--font-size-base);
    font-weight: 500;
    border-radius: 4px;
    transition: background-color 0.3s ease, transform 0.1s ease;
    &:hover {
      background-color: ${props => props.isDarkMode ? '#00cc00' : '#006600'};
      transform: translateY(-1px);
    }
    &:active {
      transform: translateY(1px);
    }
  }

  input, textarea {
    background-color: ${props => props.isDarkMode ? 'var(--secondary-dark)' : 'var(--secondary-light)'};
    color: ${props => props.isDarkMode ? 'var(--text-dark)' : 'var(--text-light)'};
    border: 1px solid ${props => props.isDarkMode ? 'var(--primary-dark)' : 'var(--primary-light)'};
    padding: 10px;
    font-family: 'Fira Code', 'Courier New', Courier, monospace;
    font-size: 16px;
    border-radius: 4px;
    transition: border-color 0.3s ease;
    &:focus {
      outline: none;
      border-color: ${props => props.isDarkMode ? '#00cc00' : '#006600'};
    }
  }

  [lang="ar"] {
    font-family: 'Droid Arabic Kufi', Arial, sans-serif;
    font-weight: 700;
    line-height: 1.5;
    font-size: var(--font-size-base);
  }

  [lang="ar"] h1, [lang="ar"] h2, [lang="ar"] h3, [lang="ar"] h4, [lang="ar"] h5, [lang="ar"] h6,
  [lang="ar"] .nav-item, [lang="ar"] a {
    font-family: 'Droid Arabic Kufi', Arial, sans-serif;
    font-weight: 700;
  }

  [dir="rtl"] {
    text-align: right;
  }

  [dir="rtl"] input,
  [dir="rtl"] textarea {
    text-align: right;
  }

  [dir="rtl"] .list {
    padding-right: 20px;
    padding-left: 0;
  }

  footer, .footer, #footer, [class*="footer"] {
    border-bottom: none !important;
  }

  // If it's a pseudo-element, you might need something like this:
  body::after, #root::after, .main-container::after {
    display: none !important;
  }

  body, #root, .main-container {
    position: relative;
    
    &::after {
      content: '';
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      height: 2px; // Adjust this value to be sure it covers the line
      background-color: ${props => props.isDarkMode ? 'var(--bg-dark)' : 'var(--bg-light)'};
      z-index: 9998; // Just below the chat button
    }
  }
`;

export default GlobalStyle;