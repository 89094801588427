import React, { useState, useRef, useEffect } from 'react';
import { useTheme } from '../contexts/ThemeContext';
// import emailjs from 'emailjs-com';
import {
  ChatbotContainer,
  ChatWindow,
  ChatHeader,
  ChatBody,
  ChatForm,
  ChatInput,
  ChatTextarea,
  ChatButton,
  ChatbotButton,
  MessageBubble,
  TypingIndicator,
  ErrorMessage
} from './ChatBotStyles';
import useLocalStorage from '../hooks/useLocalStorage';
import useForm from '../hooks/useForm';

const ChatBot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useLocalStorage('chatbot_messages', []);
  const [isTyping, setIsTyping] = useState(false);
  const { isDarkMode } = useTheme();
  const chatBodyRef = useRef(null);

  const { values, handleChange, resetForm, errors, validateForm } = useForm({
    name: '',
    email: '',
    message: ''
  }, validateInputs);

  const theme = {
    backgroundColor: isDarkMode ? '#1a1a1a' : '#f0f0f0',
    borderColor: isDarkMode ? '#1c1' : '#008000',
    primaryColor: isDarkMode ? '#1c1' : '#008000',
    primaryColorHover: isDarkMode ? '#00ff00' : '#006400',
    headerTextColor: isDarkMode ? '#000' : '#fff',
    inputBackgroundColor: isDarkMode ? '#333' : '#fff',
    inputTextColor: isDarkMode ? '#fff' : '#000',
    buttonTextColor: isDarkMode ? '#000' : '#fff',
    userMessageBackground: isDarkMode ? '#1c1' : '#008000',
    userMessageText: isDarkMode ? '#000' : '#fff',
    botMessageBackground: isDarkMode ? '#333' : '#e0e0e0',
    botMessageText: isDarkMode ? '#fff' : '#000',
    typingIndicatorColor: isDarkMode ? '#1c1' : '#008000'
  };

  const toggleChat = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      const updatedMessages = clearOldMessages(messages);
      setMessages(updatedMessages);
    } else {
      setMessages([]);
    }
  };

  const sanitizeInput = (input) => {
    return input.replace(/[<>&'"]/g, (char) => {
      switch (char) {
        case '<': return '&lt;';
        case '>': return '&gt;';
        case '&': return '&amp;';
        case "'": return '&#39;';
        case '"': return '&quot;';
        default: return char;
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      const sanitizedName = sanitizeInput(values.name);
      const sanitizedEmail = sanitizeInput(values.email);
      const sanitizedMessage = sanitizeInput(values.message);

      // Additional check for email domain
      const emailParts = sanitizedEmail.split('@');
      if (emailParts.length === 2) {
        const domain = emailParts[1];
        if (!domain.includes('.') || domain.split('.').some(part => part.length < 2)) {
          validateForm({ ...values, email: 'invalid' });
          return;
        }
      }

      const newMessage = {
        type: 'user',
        content: sanitizedMessage,
        timestamp: Date.now()
      };
      setMessages([...messages, newMessage]);
      setIsTyping(true);
      // Simulate bot response (replace with actual API call in production)
      setTimeout(() => {
        const botResponse = {
          type: 'bot',
          content: `Thank you, ${sanitizedName}! Your message has been received. We'll get back to you at ${sanitizedEmail} soon.`,
          timestamp: Date.now()
        };
        setMessages(prevMessages => [...prevMessages, botResponse]);
        setIsTyping(false);
      }, 1000);
      resetForm();
    }
  };

  // const sendEmail = async (templateParams) => {
  //   return emailjs.send(
  //     'service_9c33fbf',
  //     'email_from_website',
  //     templateParams,
  //     '7a_PHyk39Z8dcax2W'
  //   );
  // };

  // const handleEmailResponse = (response) => {
  //   if (response.status === 200) {
  //     addMessage('bot', 'Thank you for your message. We will get back to you soon!');
  //   } else {
  //     addMessage('bot', 'Sorry, there was an error sending your message. Please try again later.');
  //   }
  // };

  // const addMessage = (type, content) => {
  //   const newMessage = { type, content, timestamp: Date.now() };
  //   setMessages(prevMessages => [...prevMessages, newMessage]);
  // };

  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <ChatbotContainer $isOpen={isOpen}>
      <ChatbotButton onClick={toggleChat} aria-label="Toggle chat">
        {isOpen ? '✕' : '💬'}
      </ChatbotButton>
      <ChatWindow $isOpen={isOpen} theme={theme}>
        <ChatHeader onClick={toggleChat} theme={theme}>
          Chat with Us
          <span>{isOpen ? '▼' : '▲'}</span>
        </ChatHeader>
        {isOpen && (
          <>
            <ChatBody ref={chatBodyRef}>
              {messages.map((msg, index) => (
                <MessageBubble key={index} $isUser={msg.type === 'user'} theme={theme}>
                  {msg.content}
                </MessageBubble>
              ))}
              {isTyping && (
                <TypingIndicator theme={theme}>
                  <span></span>
                  <span></span>
                  <span></span>
                </TypingIndicator>
              )}
            </ChatBody>
            <ChatForm onSubmit={handleSubmit}>
              <ChatInput
                type="text"
                name="name"
                value={values.name}
                onChange={handleChange}
                placeholder="Your name"
                aria-label="Your name"
                theme={theme}
              />
              {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
              <ChatInput
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                placeholder="Your email"
                aria-label="Your email"
                theme={theme}
              />
              {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
              <ChatTextarea
                name="message"
                value={values.message}
                onChange={handleChange}
                placeholder="Your message here"
                aria-label="Your message"
                theme={theme}
              />
              {errors.message && <ErrorMessage>{errors.message}</ErrorMessage>}
              <ChatButton type="submit" theme={theme}>
                Send
              </ChatButton>
            </ChatForm>
          </>
        )}
      </ChatWindow>
    </ChatbotContainer>
  );
};

const clearOldMessages = (messages) => {
  const currentTime = Date.now();
  return messages.filter(msg => {
    const messageAge = currentTime - msg.timestamp;
    return messageAge < 24 * 60 * 60 * 1000; // Keep messages newer than 24 hours
  });
};

function validateInputs(values) {
  let errors = {};
  
  // Name validation
  if (!values.name.trim()) {
    errors.name = 'Name is required';
  } else if (values.name.length > 50) {
    errors.name = 'Name must be less than 50 characters';
  } else if (!/^[a-zA-Z\s]+$/.test(values.name)) {
    errors.name = 'Name should only contain letters and spaces';
  }

  // Email validation
  if (!values.email) {
    errors.email = 'Email is required';
  } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(values.email)) {
    errors.email = 'Please enter a valid email address';
  }

  // Message validation
  if (!values.message.trim()) {
    errors.message = 'Message is required';
  } else if (values.message.length > 500) {
    errors.message = 'Message must be less than 500 characters';
  }

  return errors;
}

export default ChatBot;