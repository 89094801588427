import en from '../translations/en.json';

let currentLanguage = 'en';
let loadedLanguages = { en };

const loadLanguage = async (lang) => {
  if (loadedLanguages[lang]) return;

  try {
    const module = await import(`../translations/${lang}.json`);
    loadedLanguages[lang] = module.default;
  } catch (error) {
    console.error(`Failed to load language: ${lang}`, error);
  }
};

const setLanguage = async (lang) => {
  await loadLanguage(lang);
  if (loadedLanguages[lang]) {
    currentLanguage = lang;
  } else {
    console.warn(`Language ${lang} not available, falling back to English`);
  }
};

const translate = (key, params = {}, count) => {
  const keys = key.split('.');
  let translation = loadedLanguages[currentLanguage];

  for (const k of keys) {
    translation = translation[k];
    if (translation === undefined) {
      console.warn(`Translation not found for key: ${key} in language: ${currentLanguage}`);
      return loadedLanguages['en'][key] || key;
    }
  }

  if (typeof translation === 'object' && count !== undefined) {
    translation = pluralize(translation, count);
  }

  return interpolate(translation, params);
};

const pluralize = (translations, count) => {
  if (count === 1 && translations.one) return translations.one;
  if (count === 0 && translations.zero) return translations.zero;
  return translations.other || translations.one || '';
};

const interpolate = (text, params) => {
  return text.replace(/{(\w+)}/g, (_, key) => params[key] || `{${key}}`);
};

export { setLanguage, translate };