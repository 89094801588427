const styles = {
  body: {
    fontFamily: 'monospace',
    fontSize: '20px',
    lineHeight: 1.4,
    margin: 0,
    padding: '15px',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '2000px',
  },
  content: {
    maxWidth: '1000px',
    margin: '0 auto',
    flex: 1,
    padding: '20px'
  },
  loading: {
    textAlign: 'center',
    padding: '20px',
    fontSize: '18px',
    color: '#1c1',
  },
};

export default styles;
