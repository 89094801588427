import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from './contexts/ThemeContext';
import { LanguageProvider } from './contexts/LanguageContext';
import { AuthProvider } from './contexts/AuthContext';
import AppContent from './AppContent';
import ErrorBoundary from './components/ErrorBoundary';
import initializeFirebase from './firebaseInit';
import './css/fonts.css';
import './styles/rtl.css';
import GlobalStyle from './styles/globalStyles';

const App = () => {
  useEffect(() => {
    initializeFirebase();
  }, []);

  return (
    <Router>
      <ErrorBoundary>
        <ThemeProvider>
          <LanguageProvider>
            <AuthProvider>
              <GlobalStyle />
              <AppContent />
            </AuthProvider>
          </LanguageProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </Router>
  );
};

export default App;