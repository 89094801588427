import styled from 'styled-components';

export const ChatbotContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
`;

export const ChatWindow = styled.div`
  display: ${props => props.$isOpen ? 'flex' : 'none'};
  flex-direction: column;
  width: 300px;
  height: 400px;
  background-color: ${props => props.theme.backgroundColor};
  border: 1px solid ${props => props.theme.borderColor};
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0;
  }
`;

export const ChatHeader = styled.div`
  background-color: ${props => props.theme.primaryColor};
  color: ${props => props.theme.headerTextColor};
  padding: 10px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const ChatBody = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
`;

export const ChatForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: ${props => props.theme.backgroundColor};
`;

export const ChatInput = styled.input`
  margin-bottom: 5px;
  padding: 8px;
  border: 1px solid ${props => props.theme.borderColor};
  border-radius: 4px;
  background-color: ${props => props.theme.inputBackgroundColor};
  color: ${props => props.theme.inputTextColor};
`;

export const ChatTextarea = styled.textarea`
  margin-bottom: 5px;
  padding: 8px;
  border: 1px solid ${props => props.theme.borderColor};
  border-radius: 4px;
  background-color: ${props => props.theme.inputBackgroundColor};
  color: ${props => props.theme.inputTextColor};
  resize: none;
  height: 60px;
`;

export const ChatButton = styled.button`
  background-color: ${props => props.theme.primaryColor};
  color: ${props => props.theme.buttonTextColor};
  border: none;
  padding: 8px 15px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 4px;
  transition: background-color 0.3s ease, transform 0.1s ease;

  &:hover {
    background-color: ${props => props.theme.primaryColorHover};
    transform: translateY(-1px);
  }

  &:active {
    transform: translateY(1px);
  }
`;

export const ChatbotButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: ${props => props.theme.primaryColor};
  color: ${props => props.theme.buttonTextColor};
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
  z-index: 1001;

  &:hover {
    transform: scale(1.1);
  }
`;

export const MessageBubble = styled.div`
  max-width: 80%;
  padding: 8px 12px;
  border-radius: 12px;
  margin-bottom: 8px;
  word-wrap: break-word;
  background-color: ${props => props.$isUser ? props.theme.userMessageBackground : props.theme.botMessageBackground};
  color: ${props => props.$isUser ? props.theme.userMessageText : props.theme.botMessageText};
  align-self: ${props => props.$isUser ? 'flex-end' : 'flex-start'};
`;

export const TypingIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;

  span {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${props => props.theme.typingIndicatorColor};
    margin: 0 2px;
    animation: bounce 1.4s infinite ease-in-out both;

    &:nth-child(1) {
      animation-delay: -0.32s;
    }

    &:nth-child(2) {
      animation-delay: -0.16s;
    }
  }

  @keyframes bounce {
    0%, 80%, 100% { transform: scale(0); }
    40% { transform: scale(1); }
  }
`;

export const ErrorMessage = styled.span`
  color: #ff4444;
  font-size: 12px;
  margin-top: -5px;
  margin-bottom: 5px;
  display: block;
`;
