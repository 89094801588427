import React, { createContext, useState, useContext } from 'react';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [theme, setTheme] = useState({ textColor: '#ffffff' });

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
    setTheme(prevTheme => ({
      ...prevTheme,
      textColor: !isDarkMode ? '#ffffff' : '#000000'
    }));
  };

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleTheme, theme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};