import { initializeApp, getApps, getApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"; // Import Firestore
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage"; // Import Storage
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check"; // Import AppCheck

// Firebase configuration using environment variables
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  stripePublicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
  recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  functionsUrl: process.env.REACT_APP_FUNCTIONS_URL,
  emailJsServiceId: process.env.REACT_APP_EMAILJS_SERVICE_ID,
  emailJsTemplateId: process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
  emailJsPublicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
};

// Check if Firebase app is already initialized
let app;
if (!getApps().length) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApp(); // Use the already initialized app
}

// Initialize Firestore
const db = getFirestore(app);

const analytics = getAnalytics(app);
const storage = getStorage(app); // Initialize Storage
const auth = getAuth(app);  // Always initialize auth

// AppCheck initialization with fallback
if (process.env.NODE_ENV === "development") {
  window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(process.env.REACT_APP_RECAPTCHA_SITE_KEY),
  isTokenAutoRefreshEnabled: true
});

export { db, analytics, auth, storage, appCheck };